import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebvitals from './reportWebvitals'
import { BrowserRouter } from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        {/*  New line */}
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);
/*function sendToAnalytics({ id, name, value }) {
    ga('send', 'event', {
        eventCategory: 'Web Vitals',
        eventAction: name,
        eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
        eventLabel: id, // id unique to current page load
        nonInteraction: true, // avoids affecting bounce rate
    });
}
*/

reportWebvitals(console.log);


