import {useNavigate} from 'react-router-dom';
import './Accueil.css';
import Navbar from "../navBar/Navbar";
import Footer from "../footer/Footer";

function Accueil() {
    const navigate = useNavigate();
    const navigateRealisation = () => {
        navigate('/Realisations');
    };
    return (<div className="content">
            <Navbar/>
            <div className="accueil_photo"/>
            <div className="accueil_photo_text">
                <div className="accueil_titre_photo">PLM CONSTRUCTION INC.</div>
                <div className="accueil_sous_titre_photo">POUR LES MEILLEURS</div>
                <div className="accueil_btn_projet">
                    <button
                        onClick={navigateRealisation}
                        className="accueil_button">
                        DÉCOUVREZ NOS PROJETS
                    </button>
                </div>
            </div>
            <div className="apropos">
                <div className="apropos_gauche">
                    <div className="apropos_titre_text"> À PROPOS</div>
                    <div className="apropos_sous_titre_text">DE PLM CONSTRUCTION INC.</div>
                </div>
                <div className="apropos_droite">
                    <div className="apropos_haut">
                        <div className="apropos_text">
                            Avec plus de 10 années d’expérience dans le domaine de la construction
                            et de la rénovation résidentielle, Pierre-Luc Champoux fonde PLM Construction Inc.
                            Ayant obtenu son diplôme de charpenterie-menuiserie, ce dernier exerce son métier avec
                            passion.
                            <br/>
                            <br/>
                            <br/>
                            Que ce soit pour des travaux de rénovation, de construction neuve
                            ou commerciale de toute ampleur, PLM Construction s’entoure de gens qui
                            excellent dans leur domaine de compétence. PLM Construction a pour
                            mission de livrer un travail d’une qualité exemplaire, en respectant la
                            satisfaction du client.
                            <br/>
                            <br/>
                            <br/>
                            Nous nous efforçons d’effectuer les travaux en respectant les échéances
                            déterminées et à informer le client de l’avancement des travaux tout au long de ceux-ci.
                        </div>
                    </div>
                </div>
            </div>
            <div className="apropos_bas_container">
                <div className="apropos_bas_gauche"></div>
                    <div className="apropos_bas_droite">
                        POUR UNE CONSTRUCTION À LA HAUTEUR DE VOS ATTENTES,
                        FAITES CONFIANCE À <b>PLM CONSTRUCTION INC.</b>
            </div>
            </div>


            <Footer/>
        </div>

    );
}

export default Accueil;