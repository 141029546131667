import './footer.css';

function Footer() {
    return (<>
            <div className="footer">

                    <div className="footer_body">
                        <div className='footer_venez'>
                            <div className="footer_titre">VENEZ-NOUS VOIR</div>
                            <div className="footer_adresse_container">
                                <div className="footer_adresse_nom"> PLM CONSTRUCTION INC</div>
                                <div className="footer_adresse">
                                    620 rue John-O Donigan,
                                <br/>Magog (Québec)  <div className="footer_code_postal">J1X 0A8</div></div>

                                <div className="footer_tel">819-434-3320</div>
                                <div className="footer_RBQ">N{'\u00b0'} RBQ: 5681-6887-01</div>
                            </div>
                        </div>

                        <div className="footer_social">
                            <div className="footer_social_titre">SUIVEZ-NOUS</div>
                            <div className="footer_social_logos">
                                <a className="footer_logo_fb" href="https://www.facebook.com/plmconstructioninc">
                                    </a>
                                <a className="footer_logo_insta" href="https://www.instagram.com/plmconstruction/">
                                   </a>
                            </div>
                        </div>
                </div>
                <div className="footer_fin">INFO@PLMCONSTRUCTIONINC.COM</div>
            </div>
        </>);
}

export default Footer;