import './Navbar.css';

function Navbar() {
    return (
        <nav className="navbar">
            <ul className="navbar-links">
                <li><a href="/">Accueil</a></li>
                <li><a href="/Realisations">Réalisations</a></li>
                <li><a href="/contact-us">Nous joindre</a></li>
            </ul>
        </nav>
    );
}

export default Navbar;