import React, {useEffect, useRef, useState} from 'react';
import './Carousel.css';
import Foward from "../../Pictures/Logo/Forward.ico"
import Back from "../../Pictures/Logo/Back.ico"

const Carousel = ({images, onClose}) => {
    const carouselRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (carouselRef.current && !carouselRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const [activeIndex, setActiveIndex] = useState(1);
    const nextSlide = () => {
        setActiveIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 1 : prevIndex + 1
        );
    };
    const prevSlide = () => {
        setActiveIndex((prevIndex) =>
            prevIndex === 1 ? images.length - 1 : prevIndex - 1
        );
    };
    return (
        <div className="carousel">
            <div className="carousel_container" ref={carouselRef}>
                <img
                    src={images[activeIndex]}
                    alt={`Slide ${activeIndex}`}
                    className="carousel__img"
                />
                <br/>
                <br/>
                <button onClick={prevSlide} className="carousel__btn carousel__btn--prev">
                    <img className="prev" src={Back} alt=""/>
                </button>
                <button onClick={nextSlide} className="carousel__btn carousel__btn--next">
                    <img className="next" src={Foward} alt=""/>
                </button>
            </div>
        </div>
    );
};

export default Carousel;