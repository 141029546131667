import {Route, Routes, Navigate} from 'react-router-dom';
import {useHistory} from "react-router-dom";
import Accueil from './components/accueil/Accueil';
import Realisations from './components/realisation/Realisations';
import Contact from './components/nous_joindre/Contact'
import './App.css';

function App() {
    return (
        <div className="App">
                <Routes>
                    <Route path='/' element={<Accueil/>}/>
                    <Route path='/realisations' element={<Realisations/>}/>
                    <Route path='/contact-us' element={<Contact/>}/>
                    <Route path='*' element={<Navigate to='/' />} />
                </Routes>
        </div>
    );
}

export default App;
