import Navbar from "../navBar/Navbar";
import "./Contact.css";
import Apcha from "../../Pictures/Logo/logo_apcha.svg";

function Contact() {
    return (
        <div className="joindre">
            <Navbar/>
            <div className="joindre_haut">
                <div className="joindre_titre">
                    PRÊT À TRAVAILLER ENSEMBLE?
                </div>
                <div className="joindre_text">
                    Contactez-nous, nous ferons 	équipe afin que votre rêve devienne réalité, et ce dans le respect
                        des échéanciers.
                </div>
            </div>

            <div className="joindre_milieu">
                <div className="joindre_gauche">
                    <div className="joindre_bas_titre1">VENEZ-NOUS VOIR</div>
                    <div className="joindre_adresse">
                        <div className="joindre_adresse_nom"> PLM CONSTRUCTION INC</div>
                        <br/>620 rue John-O Donigan,
                        <br/>Magog (Québec) <div className="joindre_code_postal"> J1X 0A8 </div>
                        <br/>
                        <div className="joindre_tel">819-434-3320</div>
                        <div className="joindre_rbq">N{'\u00b0'} RBQ: 5681-6887-01</div>
                    </div>
                    <div className="btncontainer">
                    <a href="mailto:info@plmconstructioninc.com">
                        <button className="joindre_contactBtn">ÉCRIVEZ-NOUS</button>
                    </a>
                    </div>
                </div>
                <div className="joindre_droite">
                    <div className="joindre_bas_titre2">SUIVEZ-NOUS</div>
                    <div className="joindre_logo">
                        <a className="joindre_logoFB"
                           href="https://www.facebook.com/plmconstructioninc">
                        </a>

                        <a className="joindre_logoInsta"
                           href="https://www.instagram.com/plmconstruction/">
                        </a>
                    </div>
                    <div className="logo_apcha">
                        <img src ={Apcha} alt="apcha"/>
                    </div>
                </div>

            </div>
            <div className="joindre_foot">

                INFO@PLMCONSTRUCTIONINC.COM

            </div>

        </div>
    );

}

export default Contact;