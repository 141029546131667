import React, {useState} from 'react';
import Navbar from "../navBar/Navbar";
import Carousel from "../carousel/Carousel";
import Footer from "../footer/Footer";
import Plus from '../../Pictures/Logo/Plus.ico';
import "./Realisation.css";

function Realisations() {

    const images_charpente = [];
    for (let i = 0; i <= 4; i++) {
        images_charpente.push(require(`../../Pictures/Realisation/charpente_carrousel/charpente_interieur_${i}.jpg`))
    }
    const images_revetement = [];
    for (let i = 0; i <= 2; i++) {
        images_revetement.push(require(`../../Pictures/Realisation/revetement_exterieur_carrousel/revetement_exterieur_${i}.jpg`))
    }
    const images_st_claude = [];
    for (let i = 0; i <= 4; i++) {
        images_st_claude.push(require(`../../Pictures/Realisation/St_Claude_carrousel/st_claude_${i}.jpg`))
    }
    const images_theatre = [];
    for (let i = 0; i <= 3; i++) {
        images_theatre.push(require(`../../Pictures/Realisation/theatre_carrousel/projet_theatre_${i}.jpg`))
    }

    const [isCarouselOpen1, setIsCarouselOpen1] = useState(false);
    const handleOpenCarousel1 = () => {
        setIsCarouselOpen1(true)
    };

    const handleCloseCarousel1 = () => {
        setIsCarouselOpen1(false)
    };

    const [isCarouselOpen2, setIsCarouselOpen2] = useState(false);
    const handleOpenCarousel2 = () => {
        setIsCarouselOpen2(true)
    };

    const handleCloseCarousel2 = () => {
        setIsCarouselOpen2(false)
    };

    const [isCarouselOpen3, setIsCarouselOpen3] = useState(false);
    const handleOpenCarousel3 = () => {
        setIsCarouselOpen3(true)
    };

    const handleCloseCarousel3 = () => {
        setIsCarouselOpen3(false)
    };
    const [isCarouselOpen4, setIsCarouselOpen4] = useState(false);
    const handleOpenCarousel4 = () => {
        setIsCarouselOpen4(true)
    };

    const handleCloseCarousel4 = () => {
        setIsCarouselOpen4(false)
    };

    return (
        <div className="content">
            <Navbar/>
            <div className="realisation_titre">NOS PROJETS</div>
            <div className="realisation_projets">
                <div className="colone">
                    <div className="colone_haut">
                        <div className="container_carousel">
                            <img className="imageCarousel" src={images_charpente[0]} alt={"Charpente"}/>
                            <button className="btnCarousel" onClick={handleOpenCarousel1}>
                                <div>{isCarouselOpen1 && (
                                    <Carousel images={images_charpente} onClose={handleCloseCarousel1}/>)}</div>
                                <div className="nomBtn">
                                    <div className="btngauche"> CHARPENTE</div>
                                    <div className="btndroite"><img className="imageplus" src={Plus} alt=""/> </div>
                                </div>

                            </button>
                        </div>
                    </div>
                    <div className="colone_bas">
                        <div className="container_carousel">
                            <img className="imageCarousel" src={images_theatre[0]} alt={"Projet Theatre"}/>
                            <button className="btnCarousel" onClick={handleOpenCarousel2}>
                                <div>{isCarouselOpen2 && (
                                    <Carousel images={images_theatre} onClose={handleCloseCarousel2}/>)}</div>
                                <div className="nomBtn">
                                    <div className="btngauche"> PROJET THÉÂTRE</div>
                                    <div className="btndroite"><img className="imageplus" src={Plus} alt=""/> </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="colone">
                    <div className="colone_haut">
                        <div className="container_carousel">
                            <img className="imageCarousel" src={images_st_claude[0]} alt={"Projet St-Claude"}/>
                            <div className="">
                                <button className="btnCarousel"
                                        onClick={handleOpenCarousel3}>
                                    <div>
                                        {isCarouselOpen3 && (
                                            <Carousel images={images_st_claude} onClose={handleCloseCarousel3}/>)}</div>
                                    <div className="nomBtn">
                                        <div className="btngauche"> PROJET ST-CLAUDE</div>
                                        <div className="btndroite"><img className="imageplus" src={Plus} alt=""/> </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="colone_bas">
                        <div className="container_carousel">
                            <img className="imageCarousel"
                                 src={images_revetement[0]}
                                 alt={"Revetement Exterieur"}
                            />

                            <div className="">
                                <button className="btnCarousel"
                                        onClick={handleOpenCarousel4}>

                                    <div>
                                        {isCarouselOpen4 && (
                                            <Carousel images={images_revetement} onClose={handleCloseCarousel4}/>)}</div>
                                    <div className="nomBtn">
                                        <div className="btngauche"> REVÊTEMENT EXTÉRIEUR</div>
                                        <div className="btndroite"><img className="imageplus" src={Plus} alt=""/> </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Footer/>
    </div>
    );
}

export default Realisations;